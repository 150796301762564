var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"flex-1 md:flex md:overflow-y-hidden"},[_c('div',{staticClass:"md:flex-1 md:flex md:space-x-4"},[_c('div',{staticClass:"p-4 text-sm bg-white rounded md:w-72 md:overflow-y-auto"},[(_vm.parent)?_c('router-link',{staticClass:"flex items-center text-green-500 underline",attrs:{"to":{
          name: _vm.getRouteName(_vm.parent.type),
          params: {
            id: _vm.parent.id
          }
        }}},[_c('FontAwesomeIcon',{attrs:{"icon":['far', 'arrow-left']}}),_c('div',{staticClass:"ml-2"},[_vm._v(_vm._s(_vm.parent.title))])],1):(_vm.current)?_c('router-link',{staticClass:"flex items-center text-green-500 underline",class:{
          'mb-8': _vm.children.length > 0
        },attrs:{"to":{
          name: _vm.getRouteName(_vm.current.type)
        }}},[_c('FontAwesomeIcon',{attrs:{"icon":['far', 'arrow-left']}}),_c('div',{staticClass:"ml-2"},[_c('PTranslation',{attrs:{"k":"Common.General.Back"}})],1)],1):_vm._e(),_c('div',{staticClass:"hidden md:block"},[(_vm.children.length > 0)?_c('ul',_vm._l((_vm.children),function(child,index){return _c('li',{key:index},[_c('router-link',{staticClass:"flex items-center text-green-500 underline",attrs:{"to":{
                name: _vm.getRouteName(child.type),
                params: {
                  id: child.id
                }
              }}},[_vm._v(" "+_vm._s(child.title)+" ")])],1)}),0):_vm._e()]),_c('div',{staticClass:"md:hidden"},[(_vm.children.length > 0)?_c('PSelect',{attrs:{"items":_vm.children,"itemText":"title","itemValue":"id","placeholder":"Meny"},model:{value:(_vm.selectedChild),callback:function ($$v) {_vm.selectedChild=$$v},expression:"selectedChild"}}):_vm._e()],1)],1),_c('div',{staticClass:"p-4 mt-4 overflow-y-auto bg-white rounded md:mt-0 md:flex-1"},[(_vm.current)?[_c('h1',{staticClass:"text-2xl font-medium",domProps:{"innerHTML":_vm._s(_vm.current.title)}}),_c('div',{staticClass:"text-sm text-gray-500"},[_vm._v(_vm._s(_vm._f("date")(_vm.current.created)))]),_c('div',{staticClass:"pb-4 mt-2 mb-4 font-medium border-b border-gray-200",domProps:{"innerHTML":_vm._s(_vm.current.abstract)}}),_c('div',{staticClass:"text-sm rte",domProps:{"innerHTML":_vm._s(_vm.current.text)}})]:(_vm.children.length > 0)?_vm._l((_vm.children),function(child,index){return _c('div',{key:index,staticClass:"mb-6"},[(!child.parentId)?_c('RouterLink',{staticClass:"group",attrs:{"to":{
              name: _vm.getRouteName(child.type),
              params: {
                id: child.id
              }
            }}},[_c('h2',{staticClass:"text-sm font-semibold text-green-600 underline group-hover:text-black"},[_vm._v(_vm._s(child.title))]),_c('div',{staticClass:"text-xs"},[_vm._v(_vm._s(_vm._f("date")(child.created))+" / "+_vm._s(child.createrName))]),_c('p',{staticClass:"mt-1 text-sm"},[_vm._v(_vm._s(child.abstract))])]):_vm._e()],1)}):_vm._e()],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }